import axios from "axios";
const Bussiness = (data) => {
  console.log("enquiry");
  var formdata = new FormData();
  formdata.append("formType", "enquiry");
  formdata.append("name", data.name);
  formdata.append("orgname", data.orgname);
  formdata.append("email", data.email);
  formdata.append("phone", data.phone);
  return axios.post(
    "https://script.google.com/macros/s/AKfycbztFVHEnyAunkuRandzARH7UKinYD8snIMBfSW2lEj0plo_vDCB5bOWif-zRt9uvBQiow/exec",
    formdata
  );
};

const NewsLetter = (email) => {
  var formdata = new FormData();
  formdata.append("formType", "newsletter");
  formdata.append("email", email);
  return axios.post(
    "https://script.google.com/macros/s/AKfycbztFVHEnyAunkuRandzARH7UKinYD8snIMBfSW2lEj0plo_vDCB5bOWif-zRt9uvBQiow/exec",
    formdata
  );
};

export { Bussiness, NewsLetter };
